import React, { useState } from "react"
import PropTypes from "prop-types"
import Box from "./box"
import Input from "./input"

const CounterInput = ({ min, max, initialValue, ...restProps }) => {
  const [count, setCount] = useState(initialValue)

  const setSafeCount = (newCount) => {
    if (newCount > max || newCount < min) {
      return
    }
    setCount(newCount)
  }

  const onChangeInput = (e) => {
    const newCount = !e.target.value ? 0 : parseInt(e.target.value)
    if (isNaN(newCount)) {
      return
    }
    setSafeCount(newCount)
  }

  const onKeyDownInput = (e) => {
    if (e.key === "Enter" || e.key === "ArrowUp") {
      e.preventDefault()
      setSafeCount(count + 1)
    }
    if (e.key === "ArrowDown") {
      e.preventDefault()
      setSafeCount(count - 1)
    }
  }

  const onClickClap = (e) => {
    setSafeCount(count + 1)
  }

  return (
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: 12 }}>
        <Input
          align="center"
          value={count}
          {...restProps}
          onChange={onChangeInput}
          onKeyDown={onKeyDownInput}
        />
      </Box>
      <Box
        as="button"
        type="button"
        sx={{
          appearance: "none",
          bg: "background",
          width: 12,
          height: 12,
          fontSize: 6,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "contrast",
          borderRadius: "lg",
          color: "text",
          ml: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ":hover, :focus": {
            bg: "muted",
          },
          variant: "buttons.pads",
        }}
        onClick={onClickClap}
      >
        <span role="img" aria-label="Auf den Tisch klopfende Faust nach links">
          🤛
        </span>
      </Box>
    </Box>
  )
}

CounterInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  initialValue: PropTypes.number,
}

CounterInput.defaultProps = {
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  initialValue: 0,
}

export default CounterInput

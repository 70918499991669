import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Box from "../../../../../components/box"
import Note from "../../../../../components/note"
import Text from "../../../../../components/text"
import SyllablesTask from "../../../../../components/syllables-task"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "reiseerinnerung",
          chapterId: "02-dichterwerkstatt",
          taskId: "silben",
        })
        navigate("/kurse/reiseerinnerung/02-dichterwerkstatt/silben/loesung")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Silben klopfen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Silben klopfen
            </Heading>
          </Stack>
          <Note variant="task">
            <Text size={[3, 4]}>
              Werfen wir einen Blick auf den Versbau des Gedichts. Aus wievielen
              Silben bestehen die Verse der 1. Strophe? Hölderlin hat seine
              Silben beim Schreiben direkt auf den Tisch geklopft. Das kannst du
              auch machen, indem du die Worte laut vor dich hinsagst und bei
              jeder Silbe den{" "}
              <span
                role="img"
                aria-label="Auf den Tisch klopfende Faust nach links"
              >
                🤛
              </span>
              -Button drückst.
            </Text>
          </Note>
          <Stack>
            <Stack space={3}>
              <SyllablesTask name="q-01">Der Nordost wehet,</SyllablesTask>
              <SyllablesTask name="q-02">
                Der liebste unter den Winden
              </SyllablesTask>
              <SyllablesTask name="q-03">
                Mir, weil er feurigen Geist
              </SyllablesTask>
              <SyllablesTask name="q-04">
                Und gute Fahrt verheißet den Schiffern.
              </SyllablesTask>
            </Stack>
            <Stack space={3}>
              <SyllablesTask name="q-05">Geh aber nun und grüße</SyllablesTask>
              <SyllablesTask name="q-06">Die schöne Garonne,</SyllablesTask>
              <SyllablesTask name="q-07">
                Und die Gärten von Bourdeaux
              </SyllablesTask>
              <SyllablesTask name="q-08">
                Dort, wo am scharfen Ufer
              </SyllablesTask>
            </Stack>
            <Stack space={3}>
              <SyllablesTask name="q-09">
                Hingehet der Steg und in den Strom
              </SyllablesTask>
              <SyllablesTask name="q-10">
                Tief fällt der Bach, darüber aber
              </SyllablesTask>
              <SyllablesTask name="q-11">
                Hinschauet ein edel Paar
              </SyllablesTask>
              <SyllablesTask name="q-12">
                Von Eichen und Silberpappeln;
              </SyllablesTask>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
